	// This file is automatically compiled by Webpack, along with any other files
	// present in this directory. You're encouraged to place your actual application logic in
	// a relevant structure within app/javascript and only use these pack files to reference
	// that code so it'll be compiled.


	require("@rails/ujs").start()
	require("turbolinks").start()
	require("@rails/activestorage").start()
	require("channels")
	require('jquery')
	require('jquery-ui')
	require('bootstrap')
	require("@fortawesome/fontawesome-free/js/all");
	require("chartkick")
	require("chart.js")
	require("trix")
	require("@rails/actiontext")

	import $ from 'jquery';
	import "bootstrap"
	import "../stylesheets/application";
	import "../stylesheets/global";
	import "../packs/serviceworker-companion.js";
	import 'select2/dist/css/select2.css';
	import 'select2';
	import "controllers"
	import { Turbo } from "@hotwired/turbo-rails"
	Turbo.session.drive = false

	$(document).on('turbolinks:load', function() {
		console.log("HOLAAA");

		$('#query_service_select').change(function(e){
			let value = $(this).find('option:selected').text();
			
			// Ignorar el primer elemento seleccionado
			if ($(this).find('option:selected').index() !== 0) {
				$('#query_service_tag').html(value);
			}else{
				$('#query_service_tag').html('');
			}
		});

	// Agregamos un controlador de eventos de clic a los botones con la clase btn-plus
	$(".btn-plus").click(function() {
		// Obtenemos el índice del botón actual
		var index = $(".btn-plus").index(this);
		
		// Obtenemos el elemento con la clase toggle-row correspondiente al índice y lo mostramos u ocultamos
		$(".toggle-row").eq(index).toggleClass('d-none d-inline');
	});

	// spinner.js
	document.addEventListener('turbolinks:click', function () {
	// Mostrar el spinner al hacer clic en un enlace o enviar un formulario
		document.getElementById('spinner').classList.remove('hidden');
  	});
  
	document.addEventListener('turbolinks:before-visit', function () {
		// Mostrar el spinner antes de cargar una nueva página
		document.getElementById('spinner').classList.remove('hidden');
	});
  
	document.addEventListener('turbolinks:load', function () {
		// Ocultar el spinner cuando la página ha terminado de cargar
		document.getElementById('spinner').classList.add('hidden');
	});
  
  	// Mostrar el spinner al recargar la página
	window.addEventListener('beforeunload', function () {
		document.getElementById('spinner').classList.remove('hidden');
	});
  



		$("#pathology_id").select2({
			theme: 'bootstrap',
			minimumInputLength: 1, // Requiere al menos 1 carácter antes de buscar
			language: {
				inputTooShort: function (args) {
					var remainingChars = args.minimum - args.input.length;
					return "Ingrese " + remainingChars + " letra o más para comenzar la búsqueda";
				},
				noResults: function () {
					return "No se encontraron resultados, por favor inténtelo nuevamente.";
				}
			},
			matcher: function(params, data) {
				// Filtra las opciones localmente en función de la entrada del usuario
				if (data.text.toLowerCase().indexOf(params.term.toLowerCase()) > -1) {
					return data;
				}
				return null;
			}
		});

		// Primer llamado
		$('#pathology_id').change(function(){ 
			$('.div_sub_pathology').hide();
			$("#sub_pathology_id").children().remove();
			$("#sub_pathology_id").append('<option value=>' + "-Seleccione-" + '</option>');
			$("#pathology_process_id").children().remove();
			$("#pathology_process_id").append('<option value=>' + "-Seleccione-" + '</option>');
			if ($(this).val() != ""){
				var listitems_sub_pathology = [];
				var listitems_pathology_process = [];
				let url = "../../pathologies/"+$(this).val()+"/get_listas_dependientes?pathology_id=" + $(this).val()+ "&es_medicina=" + $("#service_request_es_medicina").val()
				$.ajax({
					url: url,
					success: function(data) {
						if (data){
							//subpatologias
							if (data[0] != ""){
								$.each(data[0],function(key, value) {
									listitems_sub_pathology += '<option value="' + value.id + '">' + value.nombre + '</option>';
								});
								$("#sub_pathology_id").append(listitems_sub_pathology);
							}
							$('.div_sub_pathology').show();
							//Procedimientos
							if (data[1] != ""){
								$.each(data[1],function(key, value) {
									listitems_pathology_process += '<option value="' + value.id + '">' + value.nombre + '</option>';
								});
								$("#pathology_process_id").append(listitems_pathology_process);
							}
						}
					}
				});
			}
		});
		// Segundo llamado
		$('#pathology_id').change(function(){ 
			if ($(this).val() != "" && $("#pathology_employee_id").val() != ""){
				var listitems = [];
				let url = "../../employees/"+$("#pathology_employee_id").val()+"/get_detalle_cobertura_empleado?pathology_id=" + $(this).val()+ "&employee_id=" + $("#pathology_employee_id").val()+ "&request_id=" + $("#request_id").val()+ "&request_type=" + $("#request_type").val()
				$.ajax({
					url: url,
					success: function(data) {
						$("#detalle_cobertura_empleado").html(data);
					}
				});
			}
		});

		// Agrega un evento de clic a los botones
		$(".btn_new").on("click", function() {
			$(".icon-password", this).toggleClass('fa-eye-slash fa-eye');
			
			// Encuentra el campo de contraseña relacionado dentro del mismo .form-group
			var formGroup = $(this).closest(".form-group");
			var input = formGroup.find(".password-toggle");  
			// Cambia el tipo de campo de contraseña
			input[1].type = (input[1].type === "password") ? "text" : "password";
		});

		$('.plugin-select').select2();

		// Ocultar boton para cargar imagen en el editor de texto
		let attachment = $('.trix-button--icon-attach');
		attachment.hide();
		
		(function () {
			'use strict';
			var navbarSideCollapse = document.querySelector('#navbarSideCollapse');
			if (navbarSideCollapse) {
				navbarSideCollapse.addEventListener('click', function () {
					document.querySelector('.offcanvas-collapse').classList.toggle('open');
				});
			}
		})();

		// Muevo el uso del Datepicker desde el application.html.erb para ser usado aquí en el JS
		document.addEventListener('DOMContentLoaded', function(){
			$.datepicker.regional['es'] = {
				closeText: 'Cerrar',
				prevText: '< Anterior',
				nextText: 'Siguiente >',
				currentText: 'Hoy',
				monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
				monthNamesShort: ['Ene','Feb','Mar','Abr', 'May','Jun','Jul','Ago','Sep', 'Oct','Nov','Dic'],
				dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
				dayNamesShort: ['Dom','Lun','Mar','Mié','Juv','Vie','Sáb'],
				dayNamesMin: ['Do','Lu','Ma','Mi','Ju','Vi','Sá'],
				weekHeader: 'Sm',
				dateFormat: 'dd/mm/yy',
				firstDay: 1,
				isRTL: false,
				showMonthAfterYear: false,
				yearSuffix: '',
				orientation: 'bottom right',
				startView: 2
			};
			
			$( function() {
				$.datepicker.setDefaults($.datepicker.regional['es']);
				$("#service_request_fecha_cita").datepicker();
			} );
		});

		// Este bloque es para el menu
		const button = document.getElementById('avatar-navbar');
		const closeButton = document.querySelector('.btn-cierre-menu');
		const navigationMenu = document.querySelector('.contenedor-menu');

		button.addEventListener('click', () => {
			navigationMenu.classList.remove('d-none');
			navigationMenu.classList.remove('ocultar');
		});

		closeButton.addEventListener('click', () => {
			navigationMenu.classList.add('ocultar');
		});
		// Hasta aquí el bloque del Menu
	});


	$(document).ready(function () {
		$('.upload-image-form input[type="submit"]').hide();
		$("#service_request_file").change(function(){
			$(".upload-image-form").submit();
		});
	});
